<!--全局搜索-->
<template>
  <div class="globalSearchDiv">
    <!--面包屑-->
    <el-row class="breadTop">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <div class="content-div">
      <!--搜索区域-->
      <div class="searchDiv">
        <el-input
          v-model="keyWord"
          placeholder="请输入"
          prefix-icon="el-icon-search"
          clearable
          @keyup.enter.native="searchEnter"></el-input>
        <el-button class="moreBtn" @click="searchEnter">搜索</el-button>
      </div>
      <!--内容部分-->
      <div class="news-div listDivMinHeight">
        <div class="notice-item" v-for="(item, index) in noticeList" :key="index" @click="toDetail(item)">
          <div class="notice-item-img"><img :src="item.contentImg" alt/></div>
          <div class="rightContent">
            <div class="notice-item-title" :title="item.contentTitle">{{ item.contentTitle }}</div>
            <div class="notice-item-content">
              <text-ellipsis :text="item.contentOverview" :height="50" style="width: 100%">
                <template slot="more">...</template>
              </text-ellipsis>
            </div>
            <div class="notice-item-date flexAlignItemCenter">
              <img src="@/assets/img/giEdifice/grayTimer.svg" alt="" class="imgClass">
              {{ item.contentEditTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--分页-->
    <div class="page">
      <el-pagination
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="page"
        @current-change="pageChange"
        @size-change="handleSizeChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableTotal">
      </el-pagination>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from "axios";
import textEllipsis from '@/components/textellipsis'

export default {
  name: "globalSearch",
  components: { topPathList, textEllipsis },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '全局搜索',
          path: '/globalSearch'
        },
      ],
      keyWord: '',
      noticeList: [],
      page: 1,
      // 总条数
      tableTotal: 0,
      pageSize: 10,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    searchEnter() {
      this.getList()
    },

    getList() {
      let that = this
      let params = {
        size: that.pageSize,
        current: that.page,
        content: that.keyWord,
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/article/queryArticleAll',
        data: params
      }).then(res => {
        if (res.data.code == 200 || res.data.code == 10000) {
          that.noticeList = res.data.data.records
          that.tableTotal = res.data.data.total

        } else {
          that.$message.warning(res.data.msg)
        }
      }).catch(err => {
        that.$message.error('请求失败')
      })
    },

    // 条数变化
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    pageChange(val) {
      this.page = val
      this.getList();
    },
    // 上一页
    prevClick(val) {
      this.page = val
      this.getList()
    },
    // 下一页
    nextClick(val) {
      this.page = val
      this.getList()
    },

    // 跳转详情
    toDetail(news) {
      let pathObj = this.pathObj;
      pathObj.push({
        name: '文章详情',
        path: '/news/newsDetails'
      })

      this.$router.push({
        path: '/news/newsDetails',
        query: {
          pathObj: pathObj,
          contentId: news.contentId,
        }
      });
    }

  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.globalSearchDiv {
  text-align: center;
  background-color: #f8f9fa !important;
}
.breadTop {
  width: 1200px;
  margin: auto;
}
.content-div {
  margin: auto;
  padding: 32px 20px 12px 20px;
  width: 1160px;
  .searchDiv {
    display: flex;
    margin-bottom: 10px;
    margin-left: 15%;
    .moreBtn {
      position: relative;
      left: -15%;
      width: 100px;
      height: 52px;
      background: #186CF5;
      border-radius: 48px;
    }
  }
}
.news-div {
  margin-top: 50px;
  min-height: 487px;
  .notice-item {
    width: 1160px;
    margin: 0px auto 29px;
    padding: 13px 16px;
    background: #FFFFFF;
    border-radius: 5px;
    height: 161px;
    .notice-item-img {
      float: left;
      img {
        width: 270px;
        height: 160px;
      }
    }
    .rightContent {
      text-align: left;
      margin-left: 311px;
      margin-top: 17px;
      .notice-item-title {
        font-size: 18px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #333333;
        line-height: 27px;
        overflow: hidden;//超出隐藏
        text-overflow: ellipsis;//显示省略号
        white-space: nowrap;//禁止文本自动换行
      }
      .notice-item-content {
        margin-top: 12px;
        text-align: left;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        line-height: 23px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
      .notice-item-date {
        margin-top: 18px;
        text-align: left;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ABB2BF;
        line-height: 21px;
        .imgClass {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
    }
  }
  .notice-item:hover {
    cursor: pointer;
  }
}
.page {
  margin-bottom: 20px;
}
::v-deep .el-input__prefix {
  left: 60px;
}
::v-deep .el-input--prefix .el-input__inner {
  width: 784px;
  height: 52px;
  background: #FFFFFF;
  box-shadow: 0px 5px 25px 0px rgba(20,110,233,0.12);
  border-radius: 48px;
  border: 1px solid #E3EBF9;
}
::v-deep.el-input .el-input__suffix  {
  .el-input__suffix-inner {
    .el-icon-circle-close.el-input__clear {
      margin-right: 150px;
    }
  }
}

</style>
